<script>

import DocumentElement from '../components/DocumentElement';
import { mapState, mapGetters } from 'vuex';

export default
{
    components:
    {
        'document-element': DocumentElement
    },
    data()
    {
        return {
            closeFlag: true,
            isLoaded: false,
            isBusy: false,
            title: '',
            documentUrl: '',
            documentError: false,
            currentPage: 1,
            scaleMode: 'auto',
            scaleText: 'automatická veľkosť',
            scaleItems: [
                {code: 'auto', label: 'automatická veľkosť'},
                //{code: 'page-fit', label: 'na veľkosť strany'},
                {code: 'page-width', label: 'na šírku strany'},
                {code: '50', label: '50%'},
                {code: '75', label: '75%'},
                {code: '100', label: '100%'},
                {code: '150', label: '150%'},
                {code: '200', label: '200%'},
                {code: '300', label: '300%'},
                {code: '400', label: '400%'},
            ]
        };
    },
    computed:
    {
        ...mapState(
        {
            /*books: state => state.books,*/
            totalPages: state => state.totalPages
        }),
        ...mapGetters(
        {
            userToken: 'getUserToken',
            books: 'getBookList'
        })
    },
    created()
    {
        const scope = this;
        window.addEventListener('keyup', scope.onKeyboard, false);
        const codeType = scope.$route.params.codetype;
        let code = scope.$route.params.code;
        code = code.replace(/\.|\\|\//g,'');
        if (codeType==='public')
        {
            scope.title = '';
            scope.documentUrl = `//cdn.pulib.sk/public/${code}.pdf`;
            scope.closeFlag = false;
        }
        else if (scope.books)
        {
            let documentData = {};
            if (codeType == 'isbn')         documentData = scope.books.find(item => item.isbn === code);
            else if (codeType == 'ean')     documentData = scope.books.find(item => item.code === code);
            else                            documentData = scope.books.find(item => item.code === code);
            scope.documentUrl = `//cdn.pulib.sk/document/${documentData.code}.pdf`;
            scope.title = documentData.name || '';
            if (documentData.access=='library')
            {
                if (!scope.userToken)
                {
                    scope.close();
                }
            }
        }
        scope.currentPage = 1;
        scope.isLoaded = false;
        scope.$store.commit('setCurrentPage', scope.currentPage);
    },
    destroyed()
    {
        const scope = this;
        window.removeEventListener('keyup', scope.onKeyboard);
    },
    methods:
    {
        close()
        {
            const scope = this;
            scope.$router.push({name: 'catalog'});
        },
        onKeyboard(evt)
        {
            const scope = this;
            if (evt.key == 'ArrowRight')
            {
                if (evt.ctrlKey)        scope.viewPage(null, 1000000);
                else if (evt.shiftKey)  scope.viewPage(null, 10);
                else                    scope.viewPage(null, 1);
            }
            if (evt.key == 'ArrowLeft')
            {
                if (evt.ctrlKey)        scope.viewPage(null, -1000000);
                else if (evt.shiftKey)  scope.viewPage(null, -10);
                else                    scope.viewPage(null, -1);
            }
            if (evt.key == '+')
            {
                scope.changeZoom(null, 25);
            }
            if (evt.key == '-')
            {
                scope.changeZoom(null, -25);
            }
            if (evt.key == 'Escape')
            {
                scope.close();
            }
        },
        viewPage(pageNr, add=0)
        {
            const scope = this;
            pageNr = pageNr || scope.currentPage;
            pageNr += add;
            if (pageNr<1)                       pageNr = scope.totalPages;
            else if (pageNr>scope.totalPages)   pageNr = 1;
            scope.currentPage = pageNr;
            scope.$store.commit('setCurrentPage', scope.currentPage);
        },
        changeZoom(zoom, add=0)
        {
            const scope = this;
            let old = scope.scaleMode;
            if (isNaN(old)) old = 100;
            zoom = zoom || old;
            zoom += add;
            if (zoom<50)        zoom = 50;
            else if (zoom>400)  zoom = 400;
            scope.scaleMode = zoom;
            let itm = scope.scaleItems.find(item => item.code == zoom);
            let label = itm ? itm.label : zoom+'%';
            scope.scaleText = label;

            scope.$store.commit('setPageScale', scope.scaleMode);
        },
        flipPage(pos)
        {
            const scope = this;
            if (pos<0.5)    scope.viewPage(null, -1);
            else            scope.viewPage(null, 1);
        },
        onDocumentLoad(doc)
        {
            const scope = this;
            scope.isLoaded = true;
        },
        onDocumentError(err)
        {
            const scope = this;
            scope.documentError = err;
        },
        onDocumentClose()
        {
            const scope = this;
            scope.close();
        },
        onScaleSelected(scaleMode)
        {
            const scope = this;
            scope.scaleMode = scaleMode;
            scope.scaleText = scope.scaleItems.find(item => item.code == scaleMode)['label'];
        },
        onRenderStart()
        {
            const scope = this;
            scope.isBusy = true;
        },
        onRenderStop()
        {
            const scope = this;
            scope.isBusy = false;
        },
        toggleFullscreen()
        {
            const scope = this;
            scope.$refs['fullscreen'].toggle();
        }
    }
};
</script>

<template>

    <v-app>
        <full-screen class="full-screen" ref="fullscreen">
        <v-system-bar app window dark class="grey darken-3">
            <v-layout row justify-space-between fill-height align-center>

                <v-flex xs4 class="text-xs-left text-truncate">
                    <!--<span class="mr-4">{{title}}</span>-->

                       <div v-if="!documentError && isLoaded">
                            <v-tooltip bottom>
                                <v-btn :disabled="!isLoaded" small icon flat slot="activator" @click="changeZoom(null,-25)" class="ma-0"><v-icon>mdi-minus</v-icon></v-btn><span>Vzdialiť</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <v-btn :disabled="!isLoaded" small icon flat slot="activator" @click="changeZoom(null,25)" class="ma-0"><v-icon>mdi-plus</v-icon></v-btn><span>Priblížiť</span>
                            </v-tooltip>
                            <v-menu transition="scale-transition" dark offset-y>
                                <v-btn :disabled="!isLoaded" flat small ripple class="text-lowercase" slot="activator">{{scaleText}}</v-btn>
                                <v-list dense>
                                    <v-list-tile v-for="item in scaleItems" :key="item.code" @click="onScaleSelected(item.code)">
                                        <v-list-tile-title v-text="item.label"></v-list-tile-title>
                                    </v-list-tile>
                                </v-list>
                            </v-menu>
                       </div>

                </v-flex>

                <v-flex xs4 class="text-xs-center text-no-wrap">
                    <div v-if="!documentError && isLoaded">

                        <span class="mx-2" v-show="totalPages">
                            <v-tooltip bottom>
                                <v-btn :disabled="isBusy" depressed small flat ripple slot="activator" @click="viewPage(null,-1)" class="ma-0"><v-icon>mdi-chevron-left</v-icon></v-btn><span>Predchádzajúca strana</span>
                            </v-tooltip>
                            <span class="mx-2" v-show="totalPages">
                                {{currentPage}} / {{totalPages}}
                            </span>
                            <v-tooltip bottom>
                                <v-btn :disabled="isBusy" depressed small flat ripple slot="activator" @click="viewPage(null,1)" class="ma-0"><v-icon>mdi-chevron-right</v-icon></v-btn><span>Ďalšia strana</span>
                            </v-tooltip>
                        </span>

                    </div>
                </v-flex>

                <v-flex xs4 class="text-xs-right text-truncate">
                    <div>
                        <v-icon :disabled="!isLoaded" @click="toggleFullscreen">mdi-fullscreen</v-icon>
                        <v-icon :disabled="!isLoaded" v-if="closeFlag" @click="close">mdi-close</v-icon>
                    </div>
                </v-flex>

            </v-layout>
        </v-system-bar>

        <v-content style="overflow: auto; height: 100%;">
            <document-element v-if="documentUrl" :url="documentUrl" :page="currentPage" :scale="scaleMode" v-on:flip="flipPage" v-on:load="onDocumentLoad" v-on:error="onDocumentError" v-on:close="onDocumentClose" v-on:renderstart="onRenderStart" v-on:renderstop="onRenderStop"  />
        </v-content>
        </full-screen>
    </v-app>

</template>


<style scoped>

.full-screen
{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #525f69;
}

</style>
