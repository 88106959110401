<script>

import pdfjsWorker from "worker-loader!@bundled-es-modules/pdfjs-dist/build/pdf.worker.js";
import pdfjs from "@bundled-es-modules/pdfjs-dist/build/pdf";

import { mapState } from 'vuex';
import { debounce } from "debounce";

export default
{
    props: ["url", "page", "scale"],
    components:
    {
    },
    data()
    {
        return {
            isLoading: true,
            isError: false,
            loadingProgress: 0,
            pageInit: false,
            pageRendering: false,
            pdfUrl: '',
            pdfDoc: null,
            pdfPage: null,
            pdfCurrentPage: 1,
            pdfNumPages: 0,
            pdfScale: 'auto',
            pdfCols: false,
            onResizeDebounce: debounce(this.onResize, 300)
        };
    },
    watch:
    {
        page: function(value, old)
        {
            const scope = this;
            //console.log("Page changed from: "+old+" to:"+value);
            scope.pdfCurrentPage = value;
            scope.renderPage();
        },
        scale: function(value, old)
        {
            const scope = this;
            //console.log("Scale changed from: "+old+" to:"+value);
            scope.pdfScale = value;
            scope.renderPage();
        },
    },
    created()
    {
        const scope = this;
        scope.isError = false;
        scope.$store.commit('setTotalPages', 0);
        pdfjs.disableRange = false;
        pdfjs.disableStream = true;
        pdfjs.disableAutoFetch = true;
        pdfjs.GlobalWorkerOptions.workerPort = new pdfjsWorker();
        scope.pdfUrl = scope.url+'#statusbar=0&toolbar=0&view=Fit';
        //scope.pdfUrl = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf';
    },
    mounted()
    {
        const scope = this;
        scope.loadDocument();
    },
    destroyed()
    {
        const scope = this;
        if (scope.pdfDoc)   scope.pdfDoc.destroy();
    },
    methods:
    {
        loadDocument()
        {
            const scope = this;
            scope.isLoading = true;
            scope.$store.commit('setTotalPages', 0);
            scope.loadingProgress = 0;
            const loadingTask = pdfjs.getDocument({url: scope.pdfUrl/*, httpHeaders: { 'X-Requested-With': 'XMLHttpRequest' }*/});
            loadingTask.onProgress = function(state)
            {
                scope.loadingProgress = state.total ? Math.round(state.loaded/state.total*100) : 0;
            };
            loadingTask.onPassword = function(updateCallback, reason)
            {
                updateCallback("user_password");    //TODO: add password prompt
            };

            loadingTask.then(pdf =>
            {
                scope.pdfDoc = pdf;
                scope.pdfNumPages = pdf.numPages;
                scope.$store.commit('setTotalPages', scope.pdfNumPages);
                scope.isLoading = false;
                scope.$emit('load', scope.pdfDoc);
                scope.pdfCurrentPage = 1;
                scope.pageInit = false;
                scope.renderPage();
            })
            .catch(err =>
            {
                scope.isLoading = false;
                scope.isError = true;
                scope.$emit('error', err);
            });
        },
        renderPage()
        {
            const scope = this;
            if (!scope.pdfDoc)  return;
            const canvas = scope.$refs.canvas1;
            const ctx = canvas.getContext('2d');
            scope.pageRendering = true;
            scope.$emit('renderstart');
            scope.pdfDoc.getPage(scope.pdfCurrentPage).then(function(page)
            {
                scope.pdfPage = page;
                const ww = window.innerWidth;
                const hh = window.innerHeight;

                let viewport, scale;
                if (isNaN(scope.pdfScale))
                {
                    if (scope.pdfScale=='page-fit' || scope.pdfScale=='auto')
                    { // fit height
                        scale = (hh - 40) / page.getViewport(1.0).height;
                    }
                    else
                    { // fit width
                        scale = (ww * 0.96) / page.getViewport(1.0).width;
                    }
                }
                else
                { // exact scale
                    let s = parseFloat(scope.pdfScale) || 100;
                    scale = s/100;
                }
                viewport = page.getViewport({scale: scale, rotation: 360});

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {canvasContext: ctx, viewport: viewport};
                const renderTask = page.render(renderContext);

                renderTask.promise.then(function()
                {
                    scope.pageInit = true;
                    scope.pageRendering = false;
                    scope.$emit('renderstop');
                    /*if (pageNumPending !== null)
                    {
                         // New page rendering is pending
                        renderPage(pageNumPending);
                        pageNumPending = null;
                    }
                    */
                })
                .catch(function(err)
                {
                    // console.error(err);
                });
            });
        },
        closeDocument()
        {
            const scope = this;
            scope.$emit('close');
        },
        onContextMenu(evt)
        {
        },
        onClick(evt)
        {
            const scope = this;
            const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            const x = evt.clientX;
            if (w)  scope.$emit('flip', x/w);
        },
        onResize()
        {
            const scope = this;
            if (isNaN(scope.pdfScale) && scope.pageInit)  scope.renderPage();
        }
    }
};
</script>

<template>

 <v-layout align-start justify-center row fill-height fill-width style="background: #525f69;" v-on:contextmenu.prevent="onContextMenu($event)" v-on:click="onClick($event)" v-resize="onResizeDebounce">
    <v-flex xs12 class="text-xs-center">

        <div v-if="!isError">
            <div v-show="isLoading">
                <v-progress-linear v-model="loadingProgress" class="mt-0" query :indeterminate="!loadingProgress" color="blue lighten-3"></v-progress-linear>
                <v-progress-circular :size="30" color="white" indeterminate style="margin-top: 5em"></v-progress-circular>
                <div class="white--text mt-4">Načítavam...</div>
            </div>

            <div v-show="!isLoading" class="hvr-curl-top-right mt-1">
                <canvas ref="canvas1" style="box-shadow:0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)"></canvas>
            </div>

        </div>
        <div v-else>
            <div class="mt-5">
                <v-icon size="120" color="red" light>mdi-alert-circle-outline</v-icon>
                <p class="subheading white--text">Tento dokument nie je možné zobraziť</p>
                <v-btn depressed color="secondary" v-on:click="closeDocument">Návrat</v-btn>
            </div>
        </div>



    </v-flex>
</v-layout>

</template>

<style scoped>


.hvr-curl-top-right
{
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
}
.hvr-curl-top-right:before
{
    pointer-events: none;
    position: absolute;
    content: '';
    height: 0;
    width: 0;
    top: 0;
    right: -1px;
    background: #525f69;
    background: linear-gradient(225deg, #525f69 45%, #aaa 50%, #ccc 56%, white 80%);
    /*box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);*/
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: width, height;
    transition-property: width, height;
}
.hvr-curl-top-right:hover:before, .hvr-curl-top-right:focus:before, .hvr-curl-top-right:active:before
{
    width: 50px;
    height: 50px;
}

</style>
