import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VLayout,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResizeDebounce),expression:"onResizeDebounce"}],staticStyle:{"background":"#525f69"},attrs:{"align-start":"","justify-center":"","row":"","fill-height":"","fill-width":""},on:{"contextmenu":function($event){$event.preventDefault();return _vm.onContextMenu($event)},"click":function($event){return _vm.onClick($event)}}},[_c(VFlex,{staticClass:"text-xs-center",attrs:{"xs12":""}},[(!_vm.isError)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]},[_c(VProgressLinear,{staticClass:"mt-0",attrs:{"query":"","indeterminate":!_vm.loadingProgress,"color":"blue lighten-3"},model:{value:(_vm.loadingProgress),callback:function ($$v) {_vm.loadingProgress=$$v},expression:"loadingProgress"}}),_c(VProgressCircular,{staticStyle:{"margin-top":"5em"},attrs:{"size":30,"color":"white","indeterminate":""}}),_c('div',{staticClass:"white--text mt-4"},[_vm._v("Načítavam...")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"hvr-curl-top-right mt-1"},[_c('canvas',{ref:"canvas1",staticStyle:{"box-shadow":"0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)"}})])]):_c('div',[_c('div',{staticClass:"mt-5"},[_c(VIcon,{attrs:{"size":"120","color":"red","light":""}},[_vm._v("mdi-alert-circle-outline")]),_c('p',{staticClass:"subheading white--text"},[_vm._v("Tento dokument nie je možné zobraziť")]),_c(VBtn,{attrs:{"depressed":"","color":"secondary"},on:{"click":_vm.closeDocument}},[_vm._v("Návrat")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }